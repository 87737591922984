import {
  CreateSiteInput,
  Products,
  SiteModelFragment,
  SiteWithSiteRoleModelFragment,
  SiteWithSiteControllersModelFragment,
  UpdateSiteInput,
  PermissionGroupSiteRole,
  SiteIdentityModelFragment,
  OrgSiteWithUsersModelFragment,
  LightMapLayerIconSize,
  SubscriptionType,
} from '@spog-ui/graphql/types';
import { SiteUserInternalModel } from '@spog-ui/shared/models/organization-users';
import {
  SiteControllerInternalModel,
  SiteControllerStatus,
  SiteControllerViewModel,
  toSiteControllerInternalModelFromGQL,
} from '@spog-ui/shared/models/site-controllers';
import { SiteAdminUpsert as SiteWSModel } from '@spog-shared/events/site';
import { DateTime } from 'luxon';

export { SiteWSModel };

export const PRODUCT_NAME_COLOR = 'color';

export interface SubscriptionInfo {
  expiresOn: string;
  startsOn: string;
  expiredAlready: boolean;
  expiresSoon: boolean;
  subscriptionType: SubscriptionType;
  partner: string;
  salesPerson: string;
  blockAccess: boolean;
}

export interface SiteInternalModel {
  id: string;
  name: string;
  gateways: string[];
  timeZone: string;
  geoLocation: {
    latitude: number;
    longitude: number;
  };
  enabledProducts: Products[];
  siteControllers?: SiteControllerInternalModel[];
  organizations: string[];
  mySiteRole?: PermissionGroupSiteRole;
  subscriptionInfo?: SubscriptionInfo;
  lightMapLayerIconSize: LightMapLayerIconSize;
  hasCellLine: boolean;
}

export interface OrganizationSiteInternalModel {
  id: string;
  name: string;
  users: SiteUserInternalModel[];
}

export type EnabledProductsRESTModel = {
  remoteAccess: boolean;
  illuminate: boolean;
  sense: boolean;
  illuminatePower: boolean;
  sensePower: boolean;
  climate: boolean;
  color: boolean;
  colorFade: boolean;
  allDynamicEffects: boolean;
  monoDynamicEffects: boolean;
  chaseEffects: boolean;
  tiltDetection: boolean;
  thresholdDetection: boolean;
};

export type SiteFormModel = {
  name: string;
  gateways: string[];
  organizations: string[];
  latitude: number;
  longitude: number;
  enabledProducts: EnabledProductsRESTModel;
  lightMapLayerIconSize: LightMapLayerIconSize;
  hasCellLine: 'cell' | 'notCell';
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  subscriptionType: SubscriptionType | 'None';
  partner: string;
  salesPerson: string;
  blockAccess: boolean;
};

export type SiteFormChanges = {
  featuresChangedToDisabled: string[];
  changes: SiteFormModel;
};

export type SiteGQLModel = SiteModelFragment;
export type SiteIdentityGQLModel = SiteIdentityModelFragment;
export type OrgSiteWithUsersGQLModel = OrgSiteWithUsersModelFragment;
export type SiteWithSiteRoleGQLModel = SiteWithSiteRoleModelFragment;
export type SiteWithSiteControllersGQLModel = SiteWithSiteControllersModelFragment;

function productsToArray(apiProducts: EnabledProductsRESTModel): Products[] {
  const products: Products[] = [];

  if (apiProducts.remoteAccess) products.push(Products.REMOTE_ACCESS);
  if (apiProducts.illuminate) products.push(Products.ILLUMINATE);
  if (apiProducts.illuminatePower) products.push(Products.ILLUMINATE_POWER);
  if (apiProducts.sensePower) products.push(Products.SENSE_POWER);
  if (apiProducts.sense) products.push(Products.SENSE);
  if (apiProducts.climate) products.push(Products.CLIMATE);
  if (apiProducts.color) products.push(Products.COLOR);
  if (apiProducts.colorFade) products.push(Products.COLOR_FADE);
  if (apiProducts.allDynamicEffects) products.push(Products.ALL_DYNAMIC_EFFECTS);
  if (apiProducts.monoDynamicEffects) products.push(Products.MONO_DYNAMIC_EFFECTS);
  if (apiProducts.chaseEffects) products.push(Products.CHASE_EFFECTS);
  if (apiProducts.tiltDetection) products.push(Products.TILT_DETECTION);
  if (apiProducts.thresholdDetection) products.push(Products.THRESHOLD_DETECTION);

  return products;
}

export function productsToDictionary(products: Products[]): EnabledProductsRESTModel {
  return {
    remoteAccess: products.includes(Products.REMOTE_ACCESS),
    illuminate: products.includes(Products.ILLUMINATE),
    illuminatePower: products.includes(Products.ILLUMINATE_POWER),
    sense: products.includes(Products.SENSE),
    sensePower: products.includes(Products.SENSE_POWER),
    climate: products.includes(Products.CLIMATE),
    color: products.includes(Products.COLOR),
    colorFade: products.includes(Products.COLOR_FADE),
    allDynamicEffects: products.includes(Products.ALL_DYNAMIC_EFFECTS),
    monoDynamicEffects: products.includes(Products.MONO_DYNAMIC_EFFECTS),
    chaseEffects: products.includes(Products.CHASE_EFFECTS),
    tiltDetection: products.includes(Products.TILT_DETECTION),
    thresholdDetection: products.includes(Products.THRESHOLD_DETECTION),
  };
}

function subscriptionInfoFromGQL(subscriptionInfo: any, siteTimeZone: string) {
  return subscriptionInfo
    ? {
        startsOn: subscriptionInfo.startsOn,
        expiresOn: subscriptionInfo.expiresOn,
        expiredAlready:
          DateTime.fromISO(subscriptionInfo.expiresOn, { zone: siteTimeZone }) <
          DateTime.now(),
        expiresSoon:
          DateTime.fromISO(subscriptionInfo.expiresOn, { zone: siteTimeZone }).minus({
            days: 60,
          }) < DateTime.now(),
        subscriptionType: subscriptionInfo.subscriptionType,
        partner: subscriptionInfo.partner,
        salesPerson: subscriptionInfo.salesPerson,
        blockAccess: subscriptionInfo.accessBlocked,
      }
    : undefined;
}

export function toSiteInternalModelFromGQL(gql: SiteGQLModel): SiteInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    gateways: gql.siteControllers.map(sc => sc.id),
    timeZone: gql.timeZone ?? 'unknown',
    geoLocation: gql.geoLocation,
    enabledProducts: gql.enabledProducts,
    organizations: gql.organizations.map(organization => organization.id),
    subscriptionInfo: subscriptionInfoFromGQL(
      gql.subscriptionInfo,
      gql.timeZone ?? 'America/Chicago',
    ),
    lightMapLayerIconSize: gql.lightMapLayerIconSize,
    hasCellLine: gql.hasCellLine,
  };
}

export function toSiteInternalModelWithSiteRoleFromGQL(
  gql: SiteWithSiteRoleGQLModel,
): SiteInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    gateways: [],
    timeZone: gql.timeZone ?? 'unknown',
    geoLocation: gql.geoLocation,
    enabledProducts: gql.enabledProducts,
    organizations: [],
    mySiteRole: gql.mySiteRole,
    subscriptionInfo: subscriptionInfoFromGQL(
      gql.subscriptionInfo,
      gql.timeZone ?? 'America/Chicago',
    ),
    lightMapLayerIconSize: gql.lightMapLayerIconSize,
    hasCellLine: gql.hasCellLine,
  };
}

export function toSiteInternalModelWithSiteControllersFromGQL(
  gql: SiteWithSiteControllersGQLModel,
): SiteInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    gateways: gql.siteControllers.map(sc => sc.id),
    timeZone: gql.timeZone ?? 'unknown',
    geoLocation: gql.geoLocation,
    enabledProducts: gql.enabledProducts,
    siteControllers: gql.siteControllers
      ? gql.siteControllers.map(toSiteControllerInternalModelFromGQL)
      : [],
    organizations: gql.organizations.map(organization => organization.id),
    subscriptionInfo: subscriptionInfoFromGQL(
      gql.subscriptionInfo,
      gql.timeZone ?? 'America/Chicago',
    ),
    lightMapLayerIconSize: gql.lightMapLayerIconSize,
    hasCellLine: gql.hasCellLine,
  };
}

export function toOrganizationSiteInternalModelFromIdentityGQL(
  gql: SiteIdentityGQLModel,
): OrganizationSiteInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    users: [],
  };
}

export function toOrgSiteInternalModelFromOrgSiteWithUsersGqlModel(
  gql: OrgSiteWithUsersGQLModel,
): OrganizationSiteInternalModel {
  return {
    id: gql.node.id,
    name: gql.node.name,
    users: gql.users
      .map(userItem => {
        return { name: userItem.user.name, siteRole: userItem.siteRole };
      })
      .filter(user => user.siteRole !== PermissionGroupSiteRole.NONE),
  };
}

export function toSiteInternalModelFromWS(ws: SiteWSModel): SiteInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    gateways: ws.siteControllerIds,
    timeZone: ws.timezone,
    geoLocation: {
      latitude: ws.latitude,
      longitude: ws.longitude,
    },
    enabledProducts: ws.enabledProducts,
    organizations: [],
    subscriptionInfo: subscriptionInfoFromGQL(ws.subscriptionInfo, ws.timezone),
    lightMapLayerIconSize: ws.lightMapLayerIconSize,
    hasCellLine: ws.hasCellLine,
  };
}

export function toSiteFormModelFromInternal(model: SiteInternalModel): SiteFormModel {
  return {
    name: model.name,
    gateways: model.gateways,
    organizations: model.organizations,
    longitude: model.geoLocation.longitude,
    latitude: model.geoLocation.latitude,
    enabledProducts: productsToDictionary(model.enabledProducts),
    lightMapLayerIconSize: model.lightMapLayerIconSize,
    hasCellLine: model.hasCellLine ? 'cell' : 'notCell',
    subscriptionStartDate: model.subscriptionInfo
      ? DateTime.fromISO(model.subscriptionInfo.startsOn).toISODate()
      : '',
    subscriptionEndDate: model.subscriptionInfo
      ? DateTime.fromISO(model.subscriptionInfo.expiresOn).toISODate()
      : '',
    subscriptionType: model.subscriptionInfo?.subscriptionType ?? 'None',
    partner: model.subscriptionInfo?.partner ?? '',
    salesPerson: model.subscriptionInfo?.salesPerson ?? '',
    blockAccess: model.subscriptionInfo?.blockAccess ?? false,
  };
}

export function toCreateSiteInput(form: SiteFormModel): CreateSiteInput {
  return {
    name: form.name,
    geoLocation: {
      latitude: form.latitude,
      longitude: form.longitude,
    },
    enabledProducts: productsToArray(form.enabledProducts),
    siteControllerIds: form.gateways,
    organizationIds: form.organizations,
    lightMapLayerIconSize: form.lightMapLayerIconSize,
    hasCellLine: form.hasCellLine === 'cell',
    ...(form.subscriptionType !== 'None' && {
      subscriptionInfo: {
        ...(form.subscriptionStartDate ? { startsOn: form.subscriptionStartDate } : {}),
        ...(form.subscriptionEndDate ? { expiresOn: form.subscriptionEndDate } : {}),
        subscriptionType: form.subscriptionType,

        ...(form.partner ? { partner: form.partner } : {}),
        ...(form.salesPerson ? { salesPerson: form.salesPerson } : {}),
        ...(form.blockAccess ? { accessBlocked: form.blockAccess } : {}),
      },
    }),
  };
}

export function toUpdateSiteInput(id: string, form: SiteFormModel): UpdateSiteInput {
  return { ...toCreateSiteInput(form), id };
}

export interface SiteDetailsViewModel {
  id: string;
  name: string;
  status: SiteControllerStatus;
  timeZone: string;
  latitude: number;
  longitude: number;
}

export const createMockSiteDetailsViewModel = (
  updates?: Partial<SiteDetailsViewModel>,
): SiteDetailsViewModel => ({
  id: 'dummy-site-id',
  name: 'Dummy Site',
  status: 'ONLINE',
  timeZone: 'America/Chicago',
  latitude: 12.34,
  longitude: 56.78,
  ...updates,
});

export function getSiteStatus(
  siteControllerViews: SiteControllerViewModel[],
): SiteControllerStatus {
  if (!siteControllerViews) return 'UNKNOWN';

  // Get list of all statuses
  const statusList = siteControllerViews.map(
    siteControllerViews => siteControllerViews.status,
  );

  // All Unknown?
  if (statusList.every(status => status === 'UNKNOWN')) {
    // This should be rare, and not expected. It means that someone added the SC outside of the normal flow
    return 'UNKNOWN';
  }

  // Are all either OFFLINE or UNKNOWN?
  if (statusList.every(status => status === 'OFFLINE' || status === 'UNKNOWN')) {
    return 'OFFLINE';
  }

  // Are some OFFLINE or DEGRADED?
  if (statusList.some(status => status === 'OFFLINE' || status === 'DEGRADED')) {
    // Some are UP, but at least one is OFFLINE
    return 'DEGRADED';
  }

  // Are any PENDING?
  if (statusList.some(status => status === 'PENDING')) {
    // Some may be UP, but at least one is waiting to get the first message from the SC to confirm
    return 'PENDING';
  }

  // Are any IN PROGRESS?
  if (statusList.some(status => status === 'IN PROGRESS')) {
    // This means that the SC is currently being initialized
    return 'IN PROGRESS';
  }

  // If none are OFFLINE, PENDING or IN PROGRESS, they must be UP
  return 'ONLINE';
}

export function toSiteDetailsViewModelFromInternal(
  internal: SiteInternalModel,
  siteControllerViews: SiteControllerViewModel[],
): SiteDetailsViewModel {
  const status = getSiteStatus(siteControllerViews);

  return {
    id: internal.id,
    name: internal.name,
    status,
    timeZone: internal.timeZone,
    latitude: internal.geoLocation.latitude,
    longitude: internal.geoLocation.longitude,
  };
}
